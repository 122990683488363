<template>
  <div class="pb-96">
    <div class="vs-row hero">
      <div class="hero-text self-center mx-20">
        <p class="text-5xl font-bold pl-4">Search Results</p>
        <p class="px-4 text-lg">Lorem ipsum, dolor sit amet, consectetur adipisicing elit, sed</p>
        <vs-breadcrumb :items="items" separator="chevron_right"></vs-breadcrumb>
      </div>
    </div>
    <div class="mx-10">
      <div v-if="Array($route.query.members).length > 0" class="flex mt-10">
         <div class="avatars">
          <span v-for="(member, index) in $route.query.members" :key="index" class="avatar">
            <img :src="membersDic[member].child_photo">
          </span>
        </div>
        <div class="self-center ml-3">
          <span v-for="(member, index) in $route.query.members" :key="index">{{membersDic[member].child_name.split(',')[0]}}, </span>
        </div>
      </div>
      <div class="mr-8 my-5">
        <div v-if="$route.query.search" class="vs-row px-4 mb-base">
          <template v-if="search && search.length > 0">
            <div class="search-card vs-col xl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full pb-4 px-4" v-for="(item, index) in search" :key="item.index" :offset="index > 0 ? 2 : 0">
              <el-card class="card" :body-style="{ padding: '0px' }">
                <div class="cardBody">
                  <span class="material-icons btn">favorite</span>
                  <img :src="item.banner" class="image">
                </div>
                <div class="footer" style="padding: 14px;">
                  <span>{{item.title}}</span>
                  <div class="mt-1">
                    <a class="blue cursor-pointer">
                      <span class="link" @click="openActivityView(item)">View Details</span> 
                      <span class="material-icons float-right mr-1" style="margin-top:-4px;">open_in_new</span>
                    </a>
                  </div>
                </div>
              </el-card>
            </div>
          </template>
          <template v-else>
            <p class="text-xl text-gray-400 flex justify-center">{{$t('Sorry, we couldn\'t find any results')}}</p>
          </template>
        </div>
        <div v-else class="vs-row px-4 mb-base">
          <template v-if="search && search.length > 0">
            <div class="vs-col xl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full pb-4 px-4" v-for="(item, index) in search" :key="item.index" :offset="index > 0 ? 2 : 0">
              <el-card class="card" :body-style="{ padding: '0px' }">
                <div class="cardBody">
                  <span class="material-icons btn">favorite</span>
                  <img :src="item.banner" class="image">
                </div>
                <div class="footer" style="padding: 14px;">
                  <span>{{item.title}}</span>
                  <div class="mt-1">
                    <i class="el-icon-location blue"></i><span class="time mx-1"> {{item.location[0].branch}} | {{item.age_from}}-{{item.age_to}} Years | {{item.interests_name}}</span>
                    <div class="mx-1 bottom">
                      <span class="blue">Price: </span><span class="orange">{{item.location[0].price}} KWD</span>
                    </div>
                    <a class="blue cursor-pointer">
                      <span class="link" @click="openActivity(item)">View Details</span> 
                      <span class="material-icons float-right mr-1" style="margin-top:-4px;">open_in_new</span>
                    </a>
                  </div>
                </div>
              </el-card>
            </div>
          </template>
          <template v-else>
            <p class="text-xl text-gray-400 flex justify-center">{{$t('Sorry, we couldn\'t find any results')}}</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios.js'

export default {
  data() {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      items: [
        {
          title: 'Home',
          url: 'home',
        },
        {
          title: 'Search Results',
          active: true
        }
      ],
      search: [],
      categories: [],
      categoriesDic: {},
      membersDic: {}
    }
  },
  watch: {
    '$route.query' () {
      if (this.$route.query.search) {
        this.loadSearchString()
      } else {
        this.loadCategories()
      }
    }
  },
  methods: {
    openActivity (item) {
      this.$router.push({
        name: 'activity-details',
        params: {
          activityID: item.id
        }
      })
    },
    openActivityView (item) {
      switch (item.type) {
        case 'ACTIVITY':
          this.$router.push({
            name: 'activity-details',
            params: {
              activityID: item.id
            }
          })
        break;
        case 'CLUB':
          this.$router.push({
            name: 'club-details',
            params: {
              clubID: item.id
            }
          })
        break;
        default:
          break;
      }
    },
    loadCategories () {
      this.$vs.loading()

      axios.get('v2/categories', {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          response.data.payload.data.forEach(item => {
            this.categoriesDic[item.id] = item.name_en 
          })
          this.loadSearch()
        }
      })
    },
    loadSearch () {
      var filter = new FormData();
      filter.append("interests_id", this.$route.query.categories ? Array(this.$route.query.categories).map(item => item = Number(item)) : '')
      filter.append("area_id", this.$route.query.areas ? Array(this.$route.query.areas).map(item => item = Number(item)): '')
      filter.append("child_id", this.$route.query.members ? Array(this.$route.query.members).map(item => item = Number(item)): '')
      filter.append("rating", Number(this.$route.query.rate))
      filter.append("age_from", Number(this.$route.query.age_from))
      filter.append("age_to", Number(this.$route.query.age_to))
      filter.append("price_from", Number(this.$route.query.price_from))
      filter.append("price_to", Number(this.$route.query.price_to))
      axios.post('v1/getFiltersWeb', filter, {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.search = response.data.payload
          this.$vs.loading.close()
        }
      })
    },
    loadSearchString () {
      axios.post('v1/search', {str: this.$route.query.search},{headers: this.headers})
      .then((response) => {
        this.search = response.data.payload
      })
    }
  },
  created () {
    if (this.$route.query.search) {
      this.loadSearchString()
    } else {
      this.loadCategories()
      const userData = JSON.parse(localStorage.getItem('user_data'))
      userData.child.forEach(member => {
        this.membersDic[member.child_id] = member
      })
    }
  }
}
</script>

<style scoped>
.hero {
  background-image: url('../assets/images/footer.jpg');
}
.search-card > .el-card {
  height: 320px;
}
.el-card {
  height: 385px;
}
.time {
  font-size: 13px;
  color: #999;
}
.bottom {
  font-size: 13px;
  margin-bottom: -0px;
}
.link {
  padding-bottom: 10px;
  float: right;
  font-size: 13px;
}
.card .footer .mt-1 a {
  visibility: hidden;
}
.card:hover .footer .mt-1 a {
  visibility: visible;
}
.image {
  width: 100%;
  height: 240px;
  display: block;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both
}
.blue {
  color: #00DCDC;
}
.orange {
  color: #F89416;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn {
  color:#00DCDC;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 10%;
  right: 0%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.cardBody .btn:hover {
  background-color: #999;
}
.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #FFF;
  background-color: #F89416 !important;
  box-shadow: -1px 0 0 0 #409eff;
}
</style>
<style scoped>
.avatar {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 60px;
  border: 2px solid #00DCDC;
}
.avatar:not(:first-child) {
  margin-left: -30px;
  -webkit-mask:radial-gradient(circle 32px at -2px 50%,transparent 99%,#fff 100%);
          mask:radial-gradient(circle 0px at 0px 50%,transparent 99%,#fff 100%);
}
.avatar img {
  width: 100%;
  display: block;
}
</style>
